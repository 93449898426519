import { Icon } from 'oialbert-ui';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo.jpg';
import useAuth from '../../hooks/useAuth';
export const Navbar = () => {
  const { user } = useAuth();

  return (
    <section
      className={
        'hidden sm:flex flex-col w-64 min-h-full overflow-y-auto  items-start justify-start bg-white shadow-lg shadow-gray-200 rounded-xl px-3'
      }
    >
      <img src={Logo} alt="albert" className="mx-2 h-24 mt-10 mb-5" />
      <Link
        to="/"
        className="flex justify-start items-center gap-3 pl-3 border-b border-gray-100 border-opacity-25 cursor-pointer hover:bg-gray-50 w-full py-4 text-gray-500 hover:text-black"
      >
        <Icon.MdOutlineHome className="w-5 h-5 text-neon-900" />
        <span className=" text-sm">home</span>
      </Link>
      <Link
        to="/check-in"
        className="flex justify-start items-center gap-3 pl-3 border-b border-gray-100 border-opacity-25 cursor-pointer hover:bg-gray-50 w-full py-4 text-gray-500 hover:text-black"
      >
        <Icon.MdTaskAlt className="w-5 h-5 text-neon-900" />
        <span className=" text-sm">check-in</span>
      </Link>
      <Link
        to="/financial-order"
        className="flex justify-start items-center gap-3 pl-3 border-b border-gray-100 border-opacity-25 cursor-pointer hover:bg-gray-50 w-full py-4 text-gray-500 hover:text-black "
      >
        <Icon.MdOutlineShoppingCart className="w-5 h-5 text-neon-900" />
        <span className="text-sm">pedidos</span>
      </Link>
      {!['pdv', 'delivery'].includes(user?.partner.user_types as string) && (
        <>
          {/* <Link
            to="/promotions"
            className="flex justify-start items-center gap-3 pl-3 border-b border-gray-100 border-opacity-25 cursor-pointer hover:bg-gray-50 w-full py-4 text-gray-500 hover:text-black "
          >
            <Icon.MdOutlineShoppingBag className="w-5 h-5 text-neon-900" />
            <span className="text-sm">promoções</span>
          </Link> */}
          <Link
            to="/users"
            className="flex justify-start items-center gap-3 pl-3 border-b border-gray-100 border-opacity-25 cursor-pointer hover:bg-gray-50 w-full py-4 text-gray-500 hover:text-black "
          >
            <Icon.MdOutlineGroup className="w-5 h-5 text-neon-900" />
            <span className="text-sm">usuários</span>
          </Link>
        </>
      )}
      <Link
        to="/settings"
        className="flex justify-start gap-3 items-center pl-3 border-b border-gray-100 border-opacity-25 cursor-pointer hover:bg-gray-50 w-full py-4 text-gray-500 hover:text-black "
      >
        <Icon.MdOutlineSettings className="w-5 h-5 text-neon-900" />
        <span className="text-sm">configurações</span>
      </Link>
    </section>
  );
};
